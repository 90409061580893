import { useQuery, gql } from '@apollo/client';
import DOMPurify from 'dompurify'
import ImageWithLoader from '../components/imageWithLoader';

const GET_RECORDS = gql`
    query getRecords
    {
        records (first: 100, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
                recordsacf {
                    artistName
                    fieldGroupName
                    releaseBio
                    albumTitle
                    yearOfRelease
                    tags
                    listenLink
                    albumArtwork {
                        altText
                        caption
                        link
                        uri
                        title
                        mediaItemUrl
                        mediaItemId
                        slug
                        sourceUrl
                        mediaDetails {
                            height
                            width
                          }
                      }
                      albumArtworkWebp {
                        altText
                        caption
                        link
                        uri
                        title
                        mediaItemUrl
                        mediaItemId
                        slug
                        sourceUrl
                        mediaDetails {
                            height
                            width
                          }
                      }
            }
          }
        }
      }
`;

function DisplayRecords() {

    const { loading, error, data } = useQuery(GET_RECORDS, {
        retry: 200,
        errorPolicy: 'all',
    });
    if (loading) return <><div className='record-loading'>Fetching records...<div className='spinner'></div></div></>;
    if (error) return <p>Error : {error.message}</p>;

    return (

        <div className='records' >

            {data.records.nodes.map((record, index) => (
                <div className='record-single' key={index}>
                    <div className='record-top'>

                        <ImageWithLoader
                            src={record.recordsacf.albumArtworkWebp == null ? record.recordsacf.albumArtwork.sourceUrl : record.recordsacf.albumArtworkWebp.sourceUrl}
                            alt={record.recordsacf.albumArtwork.altText}
                            width={record.recordsacf.albumArtwork.mediaDetails.width}
                            height={record.recordsacf.albumArtwork.mediaDetails.height
                            }


                        />
                        <div className='record-info'>
                            <h3>{record.recordsacf.albumTitle} ({record.recordsacf.yearOfRelease})</h3>
                            <h3>{record.recordsacf.artistName}</h3>
                            <div className='record-bio' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.recordsacf.releaseBio) }}></div>

                            {record.recordsacf.listenLink && typeof record.recordsacf.listenLink === 'string' && (
                                <a target="_blank" rel="noreferrer" className='record-link' href={record.recordsacf.listenLink}>
                                    <span className="material-symbols-outlined">play_arrow</span>
                                    <span className='listen-text'>
                                        {record.recordsacf.listenLink.includes('spotify') ? 'Listen on Spotify' :
                                            record.recordsacf.listenLink.includes('bandcamp') ? 'Listen on Bandcamp' : 'Listen'}
                                    </span>
                                </a>
                            )}

                            <div className='record-tags'>
                                {record.recordsacf.tags.map((tag => (
                                    <span key={tag} className='record-tag'>{tag}</span>
                                )))}
                            </div>
                        </div>



                    </div>
                </div>
            ))
            }
        </div >
    );



}


export default function Records() {
    return (
        <>
            <h2 className='record-section-title'>Records</h2>
            <DisplayRecords />
        </>

    );
}