import React from 'react'
export default function About () {
  return (
    <>
      <p className='about-text'>
        I'm lewis and I do mixing, mastering and engineering sometimes. I’ve produced a few albums and have
        sound engineered lots of events and gigs.{' '}
        <a href='mailto:lewisdoesmixing@gmail.com'>Email me if you want anything done.</a>
      </p>
    </>
  )
}
