// Import everything needed to use the `useQuery` hook
import Header from './ui/header'
import Main from './ui/main'
import Footer from './ui/footer'
import React from 'react';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
 uri: 'https://server.lewisdoesmixing.co.uk/graphql',
 //uri: 'https://lewisdoesmixing.local/graphql',
  cache: new InMemoryCache(),
});

export default function App() {
  return (
    <ApolloProvider client={client}>

        <Header />
        <Main />
        <Footer />

    </ApolloProvider>
  );
}