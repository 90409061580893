import Records from "../post_types/records";
import Live from "../post_types/live";
import About from "./about";
import React from "react";


export default function Main() {

    return (
        <main>
            <div className="site-container main-container">

                <div>
                    <section id="About">
                        <About />
                    </section>
                    <section id="Records">
                        <Records />
                    </section>
                    <section id="Live">
                        <Live />
                    </section>
                </div>


            </div>
        </main>
    );
}

