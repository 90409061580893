import ScrollSpy from '../components/scrollSpy'
import React from 'react'

export default function Header () {
  const sections = ['About', 'Records', 'Live']
  return (
    <header>
      <div className='site-container header-container'>
        {/* eslint-disable-next-line */}
        <a href='#'>
          <div className='logo-container'>
            <img
              className='record-logo'
              src={require('../static/images/logos/record-1.png')}
              alt='Home Logo'
            />

            <img className='logo' src={require('../static/images/logos/ldm-logo.png')} alt='' />
          </div>
        </a>
        <ScrollSpy sections={sections} headerHeight={92} />
      </div>
    </header>
  )
}
