import React, { useState, useEffect, useRef } from 'react';

interface ImageWithLoaderProps {
  src: string;
  alt: string;
  width: number;
  height: number;
}

const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({ src, alt, width, height }) => {
  const [isLoading, setIsLoading] = useState(true);
  const spinnerRef = useRef<HTMLDivElement>(null);
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const calculateAspectRatio = () => {
      if (spinnerRef.current) {
        const spinnerWidth = spinnerRef.current.offsetWidth;
        const aspectRatio = height / width;
        const spinnerHeight = Math.ceil(aspectRatio * spinnerWidth);
        spinnerRef.current.style.height = `${spinnerHeight}px`;
      }
    };

    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeTimeoutRef.current = setTimeout(() => {
        calculateAspectRatio();
      }, 20); // Adjust the debounce delay as needed (in milliseconds)
    };

    calculateAspectRatio();
    window.addEventListener('resize', handleResize);

    const image = new Image();
    image.src = src;
    image.onload = () => {
      setIsLoading(false);
      window.removeEventListener('resize', handleResize);
    };
    image.onerror = () => {
      setIsLoading(false);
      window.removeEventListener('resize', handleResize);
    };

    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      window.removeEventListener('resize', handleResize);
      image.onload = null;
      image.onerror = null;
    };
  }, [src, width, height]);

  const LoadingSpinner: React.FC = () => {
    return (
      <div className="spinner-container" ref={spinnerRef}>
        <div className="loadingSpinner"></div>
      </div>
    );
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
        style={{ opacity: isLoading ? 0 : 1, transition: 'opacity 1.5s' }}
      />
    </div>
  );
};

export default ImageWithLoader;
