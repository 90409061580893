import React, { useEffect, useState } from 'react';

function ScrollSpy({ sections, headerHeight }) {
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        const debounceDelay = 10;

        function handleScroll() {
            const currentPos = window.scrollY;

            sections.forEach((section) => {
                const element = document.getElementById(section);
                const top = element.offsetTop - headerHeight;
                const height = element.offsetHeight;

                if (currentPos >= top && currentPos < top + height) {
                    setActiveSection(section);


                    const clickedLink = document.querySelector(`a[href="#${section}"]`);
                    if (clickedLink) {
                        clickedLink.classList.add('active');
                    }
                } else {
                    const link = document.querySelector(`a[href="#${section}"]`);
                    if (link) {
                        link.classList.remove('active');
                    }
                }
            });

            const mainHeader = document.querySelector('header');
            let mainHeaderLinks = document.querySelectorAll('a.menuLinks');
            let mainHeaderTop = mainHeader.offsetTop;

            if (currentPos >= mainHeaderTop + 16) {
                mainHeader.classList.add('headerFade');
                mainHeaderLinks.forEach((link) => {
                    link.classList.add('menuLinksWhite');
                });
            } else {
                mainHeader.classList.remove('headerFade');
                mainHeaderLinks.forEach((link) => {
                    link.classList.remove('menuLinksWhite');
                });
            }
        }


        function handleInitialLoad() {
            const hash = window.location.hash.slice(1);
            if (sections.includes(hash)) {
                setActiveSection(hash);
            }
        }

        handleInitialLoad();

        const debouncedHandleScroll = debounce(handleScroll, debounceDelay);
        window.addEventListener('scroll', debouncedHandleScroll);

        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [sections, headerHeight]);

    const renderedLinks = sections.map((section) => (
        <li key={section}>
            <a
                href={`#${section}`}
                className={activeSection === section ? 'active menuLinks' : 'menuLinks'}

            >
                {section}
            </a>
        </li>
    ));

    return <ul>{renderedLinks}</ul>;
}

function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        const context = this;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}

export default ScrollSpy;
