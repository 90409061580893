import React from 'react';
import { useQuery, gql } from '@apollo/client';

interface Event {
    eventDate: string;
    eventName: string;
    eventRole: string;
}

interface EventNode {
    event: Event;
}

interface EventsData {
    events: {
        nodes: EventNode[];
    };
}

const GET_LIVE = gql`
    query getLive {
        events(first: 100, where: { orderby: { field: MENU_ORDER, order: ASC } }) {
            nodes {
                event {
                    eventDate
                    eventName
                    eventRole
                }
            }
        }
    }
`;

function DisplayLive() {
    const { loading, error, data } = useQuery<EventsData>(GET_LIVE);

    if (loading) return <><div className='record-loading'>Fetching events...<div className='spinner'></div></div></>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className='events'>
            {data?.events.nodes.map((eventNode, index) => (
                <div className='event' key={index}>
                    { /* eslint-disable-next-line */}
                    <div className='bullet'>- &nbsp;</div>
                    <h3>{eventNode.event.eventName} // {eventNode.event.eventDate} // {eventNode.event.eventRole}</h3>
                </div>
            ))}
        </div>
    );
}

export default function Live() {


    return (
        <>
            <div>
                <h2 className="live-title">Gigs and live stuff</h2>
                <DisplayLive />
            </div>
        </>
    );
}
