import * as React from 'react'

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    nobr?: boolean
  }
}

export default function Footer () {
  return (
    <footer>
      <div className='footer-container site-container'>
        <div className='footer-top'>
          <a href='mailto:lewisdoesmixing@gmail.com'>EMAIL</a>
          <div className='logo-container'>
            <img className='record-logo' src={require('../static/images/logos/record-1.png')} alt='' />
            <img className='logo' src={require('../static/images/logos/ldm-logo.png')} alt='' />
          </div>
          <a href='https://lewisdev.me'>WEB DEV</a>
        </div>
        <div className='footer-bottom'>
          <a href='https://olarydzewska.com'>
            WEBSITE DESIGN BY <nobr>OLARYDZEWSKA.COM</nobr>
          </a>
        </div>
      </div>
    </footer>
  )
}
